body {
  margin: 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #373f41;
  background-color: #fbfffe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button,
button {
  font-family: Georgia, serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 12px 20px;
  background-color: #3c0894;
  color: white;
  border: none;
}

.button:hover,
button:hover {
  background-color: #3a71cf;
  opacity: 1;
  cursor: pointer;
}

.Main {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
}

.Left {
  display: flex;
  flex-direction: column;
  max-width: 396px;
  max-height: 100vh;
  padding: 0;
  margin: 70px 8vw 0 3vw;
}

.Right {
  display: flex;
  flex-direction: column;
  width: 900vw;
  padding: 0;
  margin: 0 6vw 0 0;
}

.Name {
  margin: 0;
  padding: 0;
  color: #040a11;
}

.Name h1,
.Name h2 {
  margin: 0;
}

.Name h1 {
  font-size: 20px;
  font-weight: 700;
  padding: 8px 0;
}

.Name img {
  padding: 10px 0;
}

.Name h2 {
  font-size: 18px;
  font-weight: 400;
}

.Links {
  margin: 8vw 0 0 0;
  padding: 10px 0 10px 0;
  min-width: 120px;
}

.Links p {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.Links a {
  color: inherit;
  text-decoration: none;
  padding: 4px;
  margin: 2px 0;
}

.Blue:hover,
.Blue.active {
  background-color: #3a71cf;
  color: white;
}

.Teal:hover,
.Teal.active {
  background-color: #3c0894;
  color: white;
}

.Social a:hover {
  background-color: transparent;
}

.LangBar {
  display: flex;
  width: 100%;
  max-height: 50px;
  background-color: #3a71cf;
}

.LangBar a {
  text-decoration: none;
  max-width: 10vh;
  min-width: 68px;
  width: 100%;
}

.Lang {
  background-color: inherit;
  max-height: 50px;
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column;
}

.Lang img {
  padding: 6px;
  margin: auto;
  height: 75%;
  width: auto;
}

.Lang p {
  position: relative;
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: white;
  margin: -14px auto 0 auto;
  padding: 0;
  text-align: center;
}

.Lang:hover,
.Lang.selected {
  box-sizing: border-box;
  border: 6px solid white;
}

.Lang:hover p,
.Lang.selected p {
  color: #3a71cf;
}

#hover .Lang {
  background-color: #3c0894;
}

#hover .Lang:hover p {
  color: #3c0894;
}

.ProjectPreviews {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 0 0;
  margin: 0 -10px 0 0;
  width: 100%;
  height: auto;
}

.ProjPrev {
  box-sizing: border-box;
  width: 50%;
  height: auto;
  padding: 6px 10px 4px 0;
  margin: 0;
  position: relative;
}

.ProjPrev:nth-child(even) {
  padding: 6px 0 4px 0;
}

.ProjPrev img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.ProjPrevText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-shadow: 0 0 1px grey;
}

.Project {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 6px 0 0 0;
}

.Project img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

img.dropshadow {
  box-shadow: 0px 0px 2px rgba(55, 63, 65, 0.5);
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.Proj {
  margin: 40px 0 0 0;
}

.ProjIntro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 5vh 0;
  justify-content: space-around;
}
.ProjIntro h3 {
  font-size: 36px;
  padding: 0px;
  width: 100%;
  margin: 0;
  flex-basis: 50%;
}
.IntroText {
  padding: 0;
  margin: 0;
}
.IntroText p {
  font-family: Arial, sans-serif;
  color: #515758;
  font-size: 18px;
  margin: 20px 0;
}

.BuiltWith {
  font-family: Arial, sans-serif;
  color: #515758;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.BuiltWith p:nth-child(1) {
  padding-top: 0;
  margin-top: 0;
}
.BuiltWith span {
  padding-right: 10px;
}
.BuiltCat {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
}

.ProjDesc {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.DescSec {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
}

.ProjDesc p {
  font-family: Arial, sans-serif;
  color: #515758;
  font-size: 16px;
}

.ProjDesc img {
  width: 35vw;
  padding: 0;
  margin-right: 3vw;
}

.AboutMe {
  display: flex;
  align-items: flex-start;
  margin-top: 5vh;
  max-width: 1000px;
}

.AboutImg img {
  max-width: 262px;
}

.AboutText {
  margin: 0 4vw 0 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.AboutText h3 {
  margin: 0;
  padding: 0;
  font-size: 32px;
}

.AboutText p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 2vh 0;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1000px) {
  .Left {
    margin-top: 0;
  }
}

/* MOBILE */
@media only screen and (max-width: 900px) {
  .Main {
    box-sizing: border-box;
    flex-direction: column;
    margin-top: 2vh;
  }

  .Left {
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .Name {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .Name h1,
  .Name img,
  .Name h2 {
    padding: 5px;
    line-height: 100%;
  }

  .Links {
    padding: 0;
    display: flex;
    flex-direction: row;
    margin: -10px auto 0 auto;
    min-width: 0px;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .Links p {
    padding: 5px;
  }

  .Social {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Social img {
    height: 15px;
    width: auto;
    padding: 3px 3px 0 3px;
    margin: auto;
  }

  .Social a {
    padding: 0;
    margin: auto;
  }

  .Right {
    width: 100vw;
    padding: 0 5px;
  }

  .LangBar {
    width: 98vw;
  }

  .LangBar a {
    min-width: 0px;
  }

  .Lang {
    padding: 0;
    margin: 0;
  }

  .Lang img {
    padding: 0 10%;
    margin: auto;
    max-height: 75%;
    height: 5vw;
    width: auto;
  }

  .Lang p {
    display: none;
  }

  .Project {
    width: 98vw;
  }

  .Project h3 {
    font-size: 20px;
    margin: 0 auto;
  }

  .DescText {
    margin: auto;
  }

  .DescText p {
    font-size: 14px;
  }

  .ProjPrev {
    width: 98vw;
    height: auto;
    padding: 0;
  }

  .ProjPrevText {
    font-size: 12px;
  }

  .Proj {
    margin: 0 auto;
  }

  .ProjIntro {
    margin: 20px 10px;
  }

  .ProjDesc {
    flex-direction: column;
    margin: 20px 10px;
  }

  .DescSec {
    flex-direction: column;
  }

  .DescSec img {
    width: 100%;
  }

  .AboutMe {
    flex-direction: column-reverse;
    margin-top: 2vh;
    align-items: center;
  }

  .AboutImg img {
    margin-top: 2vh;
  }

  .AboutText {
    margin: 2vw;
    align-items: center;
  }

  .AboutText h3 {
    font-size: 20px;
  }

  .AboutText p {
    padding: 0.5vh 0;
  }
}
